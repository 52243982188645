import styled from "styled-components";

export const LoginContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 85px;
    
    .panel {
        max-width: 530px;
        border: 1px solid #ddd;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
        display: flex;
        flex-direction: column;
        margin-bottom: 25px;

        h2 {
            padding: 32px 96px;
            font-size: 30px;
            margin-top: 20px;
            margin-bottom: 10px;
            font-family: inherit;
            font-weight: 500;
            line-height: 1.1;
            color: inherit;
        }

        .panel-heading {
            background-color: #f5f5f5;
            border-bottom: 1px solid #ddd;
        }

        .panel-body {
            padding: 32px 96px;
            display: flex;
            flex-direction: column;
            grid-gap: 15px;
            background-color: #ffffff;

            .input-group input {
                display: block;
                width: 100%;
                height: 34px;
                padding: 6px 12px;
                font-size: 14px;
                line-height: 1.428571429;
                color: #555555;
                background-color: #fff;
                background-image: none;
                border: 1px solid #ccc;
                border-radius: 4px;
                -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
                -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
                outline: none;
            }

            button {
                padding: 6px 12px;
                font-size: 14px;
                width: 80px;
                background-color: #281E78;
                color: #ffffff;
                border-radius: 4px;
                cursor: pointer;
            }

            button:hover {
                background-color: #1a144f;
            }

            button.disabled {
                cursor: not-allowed;
                background-color: #818181;
            }
        }
    }

    .bold {
        font-weight: 600;
    }
`;