import styled from "styled-components";

export const ProtocoloContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 25px;
    margin-bottom: 85px;
    
    .row {
        width: 350px;
        /* background-color: pink; */
    }

    .modal-yellow {
        background-color: #f1b434;
        color: #1b1a19;
        border-color: #f1b434;
        padding: 15px;
        margin-top: 20px;
        border: 1px solid transparent;
        border-radius: 4px;
        display: flex;
    }

    .modal-yellow-icon {
        cursor: pointer;
    }

    .input-group input {
        display: block;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.428571429;
        color: #555555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        outline: none;
    }

    button {
        padding: 6px 12px;
        font-size: 14px;
        width: 80px;
        background-color: #281E78;
        color: #ffffff;
        border-radius: 4px;
        cursor: pointer;
    }

    button:hover {
        background-color: #1a144f;
    }

    button.disabled {
        cursor: not-allowed;
        background-color: #818181;
    }

    .btn-link {
        cursor: pointer;
    }

    .bold {
        font-weight: 600;
    }
`;