import styled from "styled-components";

export const ConsultarContainer = styled.div`
    display: flex;
    padding: 25px;
    padding-top: 0;
    height: calc(100vh - 130px);
    grid-gap: 25px;

    .left-panel {
        width: 420px;
        height: calc(100vh - 155px);
        overflow: auto;
        border: 1px solid #e1dfdd;
        background-color: #ffffff;
        outline: none;
    }

    .search-protocol {
        background-color: #fedb00;
        padding: 15px;
        box-sizing: border-box;
        position: relative;

        input {
            display: block;
            width: 100%;
            height: 34px;
            padding: 6px 12px;
            font-size: 14px;
            line-height: 1.428571429;
            color: #555555;
            background-color: #fff;
            background-image: none;
            border: 1px solid #ccc;
            border-radius: 4px;
            -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
            -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
            outline: none;
            padding-right: 35px;
        }

        img {
            position: absolute;
            right: 20px;
            width: 25px;
            top: 20px;
            cursor: pointer;
        }
    }

    .left-panel-protocols-div {
        padding: 15px;
    }

    .protocolos-card {
        position: relative;
        border-radius: 4px;
        border: 1px solid #e1dfdd;
        background-color: #faf9f8;
        padding: 10px;
        cursor: pointer;
        margin-bottom: 25px;

        .upper-part {
            div {
                display: flex;
                flex-direction: column;
            }

            .badge {
                position: absolute;
                top: 10px;
                right: 10px;
                border-radius: 10px;
            }

            /* .status {
                position: absolute;
                display: inline-block;
                min-width: 10px;
                padding: 3px 7px;
                font-size: 12px;
                font-weight: bold;
                line-height: 1;
                color: #fff;
                text-align: center;
                white-space: nowrap;
                vertical-align: middle;
                background-color: #777777;
                border-radius: 10px;
                color: #00b140;
                background-color: rgba(0, 177, 64, 0.2);
                top: 10px;
                right: 10px;
            } */
        }

        .bottom-part {
            display: flex;
            flex-direction: column;
        }
    }

    .protocolos-card.active {
        border: 1px solid #281e78;
    }

    .right-panel {
        background-color: #ffffff;
        width: 100%;
        overflow: auto;
        height: calc(100vh - 155px);
        border: 1px solid #e1dfdd;
        padding: 25px;

        .button-div {
            display: flex;
            justify-content: end;
        }

        .separator-block {
            border-bottom: 1px solid black;
            margin-bottom: 25px;
            padding-bottom: 25px;
            display: flex;
            justify-content: space-between;

            div:nth-child(1) {
                display: flex;
                flex-direction: column;

            }

            div:nth-child(2) {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            div:nth-child(3) {
                display: flex;
                flex-direction: column;
                align-items: end;
            }
        }

        .separator-block2 {
            border-bottom: 1px solid black;
            margin-bottom: 25px;
            padding-bottom: 25px;

            .separator {
                display: flex;
                justify-content: space-between;

                div:nth-child(1) {
                    display: flex;
                    flex-direction: column;
                }

                div:nth-child(2) {
                    display: flex;
                    flex-direction: column;
                    align-items: end;
                }
            }
        }

        .separator-block3 {
            border-bottom: 1px solid black;
            margin-bottom: 25px;
            padding-bottom: 25px;

            .separator {
                display: flex;
                justify-content: space-between;

                div:nth-child(1) {
                    display: flex;
                    flex-direction: column;
                }

                div:nth-child(2) {
                    display: flex;
                    flex-direction: column;
                    align-items: end;
                }
            }
        }

        .separator-block4 {
            border-bottom: 1px solid black;
            margin-bottom: 25px;
            padding-bottom: 25px;

            .separator {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;

                div:nth-child(1) {
                    display: flex;
                    flex-direction: column;
                }

                div:nth-child(2) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                div:nth-child(3) {
                    display: flex;
                    flex-direction: column;
                    align-items: end;
                }
            }
        }

        .separator-block5 {
            padding-bottom: 25px;
            margin-bottom: 25px;

            .separator {
                margin-bottom: 25px;
                padding-bottom: 25px;
            }

            .separator1 {
                display: flex;
                justify-content: space-between;

                div {
                    display: flex;
                    flex-direction: column;
                }
            }

            .separator2 {
                display: flex;
                flex-direction: column;

                .div-separator {
                    display: flex;
                    justify-content: space-between;

                    div:nth-child(1),
                    div:nth-child(2),
                    div:nth-child(3) {
                        display: flex;
                        flex-direction: column;
                    }

                    div {
                        margin-bottom: 10px;
                    }

                    div:nth-child(3) {
                        align-items: end;
                    }
                }
            }

            .separator3 {
                display: flex;
                flex-direction: column;

                .div-separator1,
                .div-separator2 {
                    display: flex;
                    justify-content: space-between;
                    
                    div:nth-child(1),
                    div:nth-child(2),
                    div:nth-child(3) {
                        display: flex;
                        flex-direction: column;
                    }

                    div:nth-child(3) {
                        align-items: end;
                    }
                }
            }
        }

        .button {
            padding: 6px 12px;
            font-size: 14px;
            background-color: #281E78;
            color: #ffffff;
            border-radius: 4px;
            cursor: pointer;
        }

        .button:hover {
            background-color: #1a144f;
        }

        .button.disabled {
            cursor: not-allowed;
            background-color: #818181;
        }
    }
`;