import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginContainer } from './Login.styles';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { useAuth } from '../../AuthContext';

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const { setUser, setIsAuthenticated } = useAuth();

    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
    };

    const handleChangePassword = (event) => {
        setPassword(event.target.value);
    };

// login.jsx
const handleLogin = () => {
    axios.post(`${API_BASE_URL}/api/auth/`, { email, password }, { withCredentials: true })
        .then(response => {
            const { id, userProfile } = response.data.user;

            const encryptedId = CryptoJS.AES.encrypt(id.toString(), SECRET_KEY).toString();
            const encryptedProfile = CryptoJS.AES.encrypt(userProfile.toString(), SECRET_KEY).toString();

            localStorage.setItem('userId', encryptedId);
            localStorage.setItem('userProfile', encryptedProfile);

            setUser({ id, profile: userProfile });
            setIsAuthenticated(true);

            //console.log("Login successful:", { id, profile: userProfile });

            navigate('/menu');
        })
        .catch(error => {
            if (error.response && error.response.data && error.response.data.message) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('Error al llamar a la API');
            }
        });
};


    const isFormValid = () => {
        return email !== '' && password !== '';
    };

    return (
        <LoginContainer>
            <div className='panel'>
                <div className='panel-heading'>
                    <h2>Sistema de Intercambio Libre (STL)</h2>
                </div>
                <div className='panel-body'>
                    <div className='input-group username-group'>
                        <label className='bold' htmlFor="username">Usuario</label>
                        <br />
                        <input type="text" id="username" className="form-control" placeholder="Nombre de usuario" aria-describedby="basic-addon1" onChange={handleChangeEmail} value={email} />
                    </div>
                    <div className='input-group password-group'>
                        <label className='bold' htmlFor="password">Contraseña</label>
                        <br />
                        <input type="password" id="password" className="form-control" placeholder="Escribe tu contraseña" aria-describedby="basic-addon1" onChange={handleChangePassword} value={password} />
                    </div>
                    <button 
                        id='login-btn' 
                        className={`btn ${!isFormValid() ? 'disabled' : ''}`} 
                        onClick={handleLogin} 
                        disabled={!isFormValid()}
                    >
                        Ingresar
                    </button>
                    {errorMessage && <div id='message-box'>{errorMessage}</div>}
                </div>
            </div>
        </LoginContainer>
    );
}
