import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContatoContainer } from './Contato.styles';
import { useAuth } from '../../AuthContext';

export default function Contato() {
    const navigate = useNavigate();
    const [opcaoSelecionada, setOpcaoSelecionada] = useState('1');
    const [formData, setFormData] = useState({
        nome: '',
        documento: '',
        email: '',
        celular: '',
        zipcode: ''
    });
    const [errors, setErrors] = useState([]);
    const [isDocumentoValid, setIsDocumentoValid] = useState(true);

    const isFormComplete = () => {
        return Object.values(formData).every(value => value.trim() !== '') &&
            validateEmail(formData.email) &&
            isDocumentoValid;
    };

    const { currentStep, setCurrentStep } = useAuth();

    useEffect(() => {
      if (currentStep < 2) {
        navigate('/dispositivos');
      } else {
        setCurrentStep(3);
      }
    }, [currentStep, setCurrentStep, navigate]);

    useEffect(() => {
        const savedProtocolo = localStorage.getItem('protocoloJson');
        if (savedProtocolo) {
            const protocoloData = JSON.parse(savedProtocolo);
            if (opcaoSelecionada === '1') {
                setFormData({
                    nome: protocoloData.nome || '',
                    documento: formatRut(protocoloData.documento || ''),
                    email: protocoloData.email || '',
                    celular: protocoloData.celular || '',
                    zipcode: formatZipCode(protocoloData.zipcode || '')
                });
            } else if (opcaoSelecionada === '2' && protocoloData.representante && protocoloData.representante.length > 0) {
                setFormData({
                    nome: protocoloData.representante[0].nome || '',
                    documento: formatRut(protocoloData.representante[0].documento || ''),
                    email: protocoloData.representante[0].email || '',
                    celular: protocoloData.representante[0].celular || '',
                    zipcode: formatZipCode(protocoloData.representante[0].zipcode || '')
                });
            } else {
                setFormData({
                    nome: '',
                    documento: '',
                    email: '',
                    celular: '',
                    zipcode: ''
                });
            }
        }
    }, [opcaoSelecionada]);

    const handleRadioChange = (e) => {
        setOpcaoSelecionada(e.target.value);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const getLabel = () => {
        return opcaoSelecionada === '1' ? `titular del producto` : `representante`;
    };

    function Voltar() {
        navigate('/dispositivos');
    }

    const formatRut = (rut) => {
        const cleanRut = rut.replace(/[^0-9kK]/g, '').toUpperCase();
        let formattedRut = '';

        if (cleanRut.length > 1) {
            const body = cleanRut.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            const verifier = cleanRut.slice(-1);
            formattedRut = `${body}-${verifier}`;
        } else {
            formattedRut = cleanRut;
        }

        return formattedRut;
    };

    const validateRutFormat = (rut) => {
        const re = /^(\d{1,3}(\.?\d{3}){2})\-?([\dkK])$/;
        return re.test(rut);
    };

    const handleDocumentoChange = (e) => {
        const rawRut = e.target.value;
        const formattedRut = formatRut(rawRut);
        setFormData({ ...formData, documento: formattedRut });
        setIsDocumentoValid(validateRutFormat(formattedRut));
    };

    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    const handlePhoneChange = (e) => {
        const { value } = e.target;
        const cleanedPhone = value.replace(/\D/g, ''); // Remove qualquer caractere que não seja dígito
        setFormData({ ...formData, celular: cleanedPhone });
    };

    const formatZipCode = (zipcode) => {
        const cleanedZipCode = zipcode.replace(/\D/g, '');
        if (cleanedZipCode.length > 5) {
            return `${cleanedZipCode.slice(0, 5)}-${cleanedZipCode.slice(5, 8)}`;
        } else {
            return cleanedZipCode;
        }
    };

    const handleZipCodeChange = (e) => {
        const formattedZipCode = formatZipCode(e.target.value);
        setFormData({ ...formData, zipcode: formattedZipCode });
    };

    function Avançar() {
        const savedProtocolo = localStorage.getItem('protocoloJson');
        let protocoloData = savedProtocolo ? JSON.parse(savedProtocolo) : {};
    
        const representanteData = {
            nome: formData.nome,
            documento: formData.documento,
            email: formData.email,
            celular: formData.celular,
            zipcode: formData.zipcode
        };
    
        if (opcaoSelecionada === '1') {
            protocoloData = {
                ...protocoloData,
                representante: [representanteData],
                zipcode: formData.zipcode
            };
        } else if (opcaoSelecionada === '2') {
            protocoloData = {
                ...protocoloData,
                representante: [representanteData],
                zipcode: ''
            };
        }
    
        //console.log(protocoloData);
        localStorage.setItem('protocoloJson', JSON.stringify(protocoloData));
        navigate('/resumo');
    }
    

    const isAdvanceDisabled = !isFormComplete();

    return (
        <>
            <ContatoContainer>
                <div className="page-content start-protocol">
                    <h2>Crear número de caso de intercambio</h2>
                    <h3 className="protocol-step">Paso 3/4: Información de retiro</h3>
                    <br />
                    <div>
                        <h3>¿Quién recogerá los productos en la Farmacia?</h3>
                        <br />
                        <div className='radiolistbutton-div'>
                            <div className='radiolistbutton'>
                                <input
                                    id="titularRadio"
                                    name="retirada"
                                    type="radio"
                                    value="1"
                                    onChange={handleRadioChange}
                                    checked={opcaoSelecionada === '1'}
                                /> El titular del producto
                            </div>
                            <div className='radiolistbutton'>
                                <input
                                    id="representanteRadio"
                                    name="retirada"
                                    type="radio"
                                    value="2"
                                    onChange={handleRadioChange}
                                    checked={opcaoSelecionada === '2'}
                                /> Un representante
                            </div>
                        </div>
                    </div>

                    <br />

                    <div className='options-div'>
                        <div className='option'>
                            <strong>Nombre del {getLabel()}</strong>
                            <input
                                className='form-control'
                                type="text"
                                name="nome"
                                value={formData.nome}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='option'>
                            <strong>RUT del {getLabel()}</strong>
                            <input
                                className='form-control'
                                type="text"
                                name="documento"
                                maxLength={12}
                                value={formData.documento}
                                onChange={handleDocumentoChange}
                            />
                            {!isDocumentoValid && formData.documento && <span className="text-danger">Campo inválido</span>}
                        </div>
                        <div className='option'>
                            <strong>Correo eletrónico del {getLabel()}</strong>
                            <input
                                className='form-control'
                                type="text"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                            />
                            {!validateEmail(formData.email) && formData.email && <span className="text-danger">Correo eletrónico inválido</span>}
                        </div>
                        <div className='option'>
                            <strong>Teléfono móvil del {getLabel()}</strong>
                            <input
                                className='form-control'
                                type="text"
                                name="celular"
                                maxLength={12}
                                placeholder='56 9 XXXX XXXX o 56 2 XXX XXX XXXX'
                                value={formData.celular}
                                onChange={handlePhoneChange}
                            />
                        </div>
                        <div className='option'>
                            <strong>Codigo postal del {getLabel()}</strong>
                            <input
                                className='form-control'
                                type="text"
                                name="zipcode"
                                value={formData.zipcode}
                                onChange={handleZipCodeChange}
                            />
                        </div>
                    </div>

                    <br />

                    <div className='buttons-div'>
                        <button
                            className='button-secondary'
                            onClick={Voltar}
                        >
                            Volver
                        </button>
                        <button
                            type="submit"
                            className={`button ${isAdvanceDisabled ? 'disabled' : ''}`}
                            onClick={Avançar}
                            disabled={isAdvanceDisabled}
                        >
                            Avanzar
                        </button>
                    </div>

                </div>
            </ContatoContainer>
        </>
    );
}
