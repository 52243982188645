import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ListIcon from '../../Components/Images/Menu/list-icon.svg';
import SearchIcon from '../../Components/Images/Menu/search-icon.svg';
import ArrowRight from '../../Components/Images/Menu/arrow-right-icon.svg';
import { MenuContainer } from './Menu.styles';
import { useAuth } from '../../AuthContext';

export default function Menu() {

    const { setCurrentStep } = useAuth();
    useEffect(() => {
      setCurrentStep(0);
    }, [setCurrentStep]);

    return (
        <MenuContainer>
            <Link to='/protocolo'>
                <div className="button-card">
                    <div className='space-icon'>
                        <img className='icon' src={ListIcon} alt="icon-list" />
                    </div>
                    <div>
                        <span className='space-name'>Crear número de caso</span>
                    </div>
                    <div className='space-arrow'>
                        <img className='arrow-icon' src={ArrowRight} alt="icon-arrow" />
                    </div>
                </div>
            </Link>
            <Link to='/consultar'>
                <div className="button-card">
                    <div className='space-icon'>
                        <img className='icon' src={SearchIcon} alt="icon-list" />
                    </div>
                    <div>
                        <span className='space-name'>Consultar número de caso</span>
                    </div>
                    <div className='space-arrow'>
                        <img className='arrow-icon' src={ArrowRight} alt="icon-arrow" />
                    </div>
                </div>
            </Link>
        </MenuContainer>
    );
}
