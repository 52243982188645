import styled from "styled-components";

export const ResumoContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 25px;
    /* margin-bottom: 85px; */

    .separator-block {
        display: flex;
        flex-direction: column;
        margin-bottom: 25px;
        padding-bottom: 25px;
        border-bottom: 1px solid #e1e0df;

        .separator-div {
            display: flex;
            flex-wrap: wrap;
            //justify-content: space-between; 
            grid-gap: 25px;

            div {
                display: flex;
                flex-direction: column;
            }
        }

        .separator-div2 {
            display: flex;
            flex-direction: column;
            grid-gap: 14px;
            flex-wrap: wrap;

            div {
                display: flex;
                flex-direction: column;
            }
        }
    }

    .separator-block:last-child,
    .separator-product:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
    }

    .separator-product {
        margin-bottom: 10px;
    }

    .modal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 25px;
        border-radius: 8px;
        background-color: #ffffff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 15px;
        z-index: 1101;
    }

    .modal-footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .buttons-div {
        width: 300px;
        display: flex;
        justify-content: space-between;
    }

    .button {
        padding: 6px 12px;
        font-size: 14px;
        background-color: #281E78;
        color: #ffffff;
        border-radius: 4px;
        cursor: pointer;
    }

    .button:hover {
        background-color: #1a144f;
    }

    .button.disabled {
        cursor: not-allowed;
        background-color: #818181;
    }

    .button-secondary {
        padding: 6px 12px;
        font-size: 14px;
        border: 1px solid #281E78;
        background-color: #ffffff;
        color: #281E78;
        border-radius: 4px;
        cursor: pointer;
    }

    .button-secondary:hover {
        background-color: #e6e6e6;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1100;
    }
`;
