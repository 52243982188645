import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoAbbott from '../Images/Navbar/logo-abbott.png';
import LogoFreestyle from '../Images/Navbar/logo-freestyle.png';
import { NavbarContainer, MenuContainer } from './Navbar.styles';
import HamburgerClose from '../Images/Navbar/hamburger-close.svg';
import HamburgerOpen from '../Images/Navbar/hamburger-open.svg';

export default function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleResize = () => {
        if (window.innerWidth > 768) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Função para redirecionar ao clicar nos logos
    const handleLogoClick = () => {
        if (location.pathname !== '/') {
            navigate('/menu');
            localStorage.removeItem("protocoloJson");
        }
    };

    return (
        <>
            <NavbarContainer>
                <img 
                    src={LogoFreestyle} 
                    alt="logo freestyle" 
                    className='logo-freestyle icone' 
                    onClick={handleLogoClick} 
                />
                <img 
                    src={LogoAbbott} 
                    alt="logo abbott" 
                    className='logo-abbott icone' 
                    onClick={handleLogoClick} 
                />
                <img 
                    src={isOpen ? HamburgerClose : HamburgerOpen} 
                    alt={isOpen ? "hamburger close" : "hamburger open"} 
                    className='hamburger-icon' 
                    onClick={handleToggle} 
                />
            </NavbarContainer>
            {isOpen && (
                <MenuContainer>
                    <img 
                        src={LogoAbbott} 
                        alt="logo abbott" 
                        className='menu-logo-abbott' 
                        onClick={handleLogoClick} 
                    />
                </MenuContainer>
            )}
        </>
    );
}
