import styled from "styled-components";

export const MenuContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 25px;
    margin-bottom: 85px;
    flex-wrap: wrap;
    grid-gap: 25px;
    

    .button-card {
        display: flex;
        background-color: #ffffff;
        border: 1px solid #ddd;
        cursor: pointer;
    }

    .button-card:hover {
        color: #333;
        background-color: #e6e6e6;
        border-color: #adadad;
    }

    .space-icon {
        display: block;
        margin: auto 0 auto 15px;
    }

    .icon {
        width: 32px;
    }

    .space-name {
        display: flex;
        margin: 24px 0 24px 14px;
        font-size: 22px;
        color: #605e5c;
        width: 268px;
        white-space: pre-wrap;
        text-align: left;
        line-height: 22px;
        font-weight: bold;
        min-height: 60px;
        align-items: center;
    }

    .space-arrow {
        display: flex;
        background-color: #281e78;
        color: #ffffff;
        align-items: center;
    }

    .arrow-icon {
        display: block;
        margin: auto 0px auto 5px;
        width: 32px;
    }
`;